<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Quick Menu Banners"
              url=""
              canSelect="none"
              :data-source="dataSource"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadProducts()"
            >
              <!-- <template #action-buttons>
                <DxButton
                  icon="textdocument"
                  hint="View"
                  :onClick="onViewDetail"
                />
              </template> -->

              <template #columns>
                <DxColumn data-field="name" caption="Name " />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import productsApi from '@api/products';
import { queryParameters } from '@/schema';

export default {
  name: 'HeaderBannersPage',
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    selectedData: [],
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(event) {
      const data = event && event.row && event.row.data ? event.row.data : null;

      if (this.isTogglingView) {
        if (data) {
          this.toggledViewData = Object.assign({}, data);
        }

        return;
      }

      const url = `/pages/sales-tools/rfq/view?id=${data.id}`;
      console.log(url);
      // this.$router.push(url);
    },
    onUpdate(event) {
      const data = event.data;
      productsApi
        .update(data)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
          }
        })
        .catch((err) => {
          if (err) {
            this.toastConfig('', 'error')
          }
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      productsApi
        .delete(event)
        .then(() => {
          this.loadProducts();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
